/**
 * Blog post archive (overview of posts)
 */

// Import statements
import React from "react"
import {graphql} from "gatsby"
//import parse from "html-react-parser"
import "../styles/files/home.scss"

import PageHeader from "../components/header/page-header"
import Card from "../components/common/card"
import Layout from "../components/layout"
import Seo from "../components/common/seo"
import Pagination from "../components/common/pagination";
import Filter from "../components/common/filter";

/**
 * Define the BlogIndex constant
 * @param data
 * @param nextPagePath
 * @param previousPagePath
 * @param basePagePath
 * @param postType
 * @param slug
 * @param name
 * @param description
 * @param totalPages
 * @param pageNumber
 * @returns {JSX.Element}
 * @constructor
 */
const BlogIndex = ({
                       data,
                       pageContext: {
                           nextPagePath,
                           previousPagePath,
                           basePagePath,
                           postType,
                           slug,
                           name,
                           description,
                           totalPages,
                           pageNumber
                       },
                   }) => {

    let title = '';
    let title_description = '';
    let posts = null;
    let taxonomies = null;

    // taxonomies
    taxonomies = data.allWpTaxonomy.edges.filter( node => node.node.connectedContentTypes.nodes[0].name === postType )

    // recipe pages (e.g. /recepten/ and /recepten/page/2/ )
    if (slug === 'recepten') {
        title = 'Recepten'
        title_description = data.allWpPage.edges.find( node => node.node.slug === slug )?.node?.content || ''
        posts = data.allWpRecipe.nodes;

    // hotspot pages (e.g. /hotspots/ and /hotspots/page/2/ )
    } else if (slug === 'hotspots') {
        title = 'Hotspots'
        title_description = data.allWpPage.edges.find( node => node.node.slug === slug )?.node?.content || ''
        posts = data.allWpHotspot.nodes;

    // book pages (e.g. /boeken/ and /boeken/page/2/ )
    } else if (slug === 'boeken') {
        title = 'Boeken'
        title_description = data.allWpPage.edges.find( node => node.node.slug === slug )?.node?.content || ''
        posts = data.allWpBook.nodes;

    // all other archive pages
    // (e.g. /category/ and /category/weetjes/page/2/ )
    // (e.g. /recipe-special-diet/eivrij/ and /recipe-special-diet/eivrij/page/2/ )
    } else {
        title = name
        title_description = description

        if (postType === 'recipe') {
            posts = data.allWpRecipe.nodes;

        } else if (postType === 'hotspot') {
            posts = data.allWpHotspot.nodes;

        } else if (postType === 'book') {
            posts = data.allWpBook.nodes;

        } else {
            posts = data.allWpPost.nodes;
        }
    }

    // sort posts by date DESC
    posts.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
    })

    // when there are no results
    if (!posts.length) {
        return (
            <Layout isHomePage>
                <Seo title="All posts"/>
                <p>
                    No blog posts found. Add posts to your WordPress site and they'll
                    appear here!
                </p>
            </Layout>
        )
    }

    //const limit = (index) => index < 5;

    // return the information
    return (
        <Layout isHomePage>

            <Seo title="All posts"/>

            <PageHeader title={title} description={title_description}/>

            <Filter taxonomies={taxonomies} slug={slug}/>

            <div className="row g-4 g-sm-2 g-md-4" id="card-wrapper">
                {posts.map((post, index) => {
                    return (<Card post={post} index={index} key={index} featured="true"/>)
                })}
            </div>

            <Pagination nextPagePath={nextPagePath} previousPagePath={previousPagePath} basePagePath={basePagePath}
                        slug={slug} totalPages={totalPages} pageNumber={pageNumber}/>

        </Layout>
    )
}

/**
 * Export the BlogIndex variable
 */
export default BlogIndex

/**
 * Get the blog post archive posts with GraphQL
 * @type {StaticQueryDocument}
 */
export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    
    # get all posts
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage { ...cardImageFields }
      }
    }
    
    # get all recipes
    allWpRecipe(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt: content
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        content
        featuredImage { ...cardImageFields }
      }
    }
    
    # get all hotspots
    allWpHotspot(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt: content
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        content
        featuredImage { ...cardImageFields }
      }
    }
    
    # get all books
    allWpBook(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt: content
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        content
        featuredImage { ...cardImageFields }
      }
    }
    
    # get all pages (for page descriptions)
    allWpPage(filter: {slug: {in: ["recepten", "boeken", "hotspots"]}}) {
      edges {
        node {
          content
          slug
          title
        }
      }
    }
    
    # get all taxonomy
    allWpTaxonomy {
      edges {
        node {
          name
          graphqlSingleName
          connectedContentTypes {
            nodes {
              name
            }
          }
        }
      }
    }
    
  }
`