/**
 * Filter component
 */

// Import statements
import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import NavPillsFilter from "./nav-pills-filter";

/**
 * Define the Filter constant
 * @param taxonomies
 * @param slug
 * @returns {JSX.Element|string}
 * @constructor
 */
const Filter = ({taxonomies, slug}) => {

    // query filter_1 menu
    const filter_1_menu_data = useStaticQuery(
        graphql`
          query {
          
            # recipes
            allWpRecipeCollection {
              edges {
                ...filterRecipeCollectionFields
              }
            }
            
            allWpRecipeCourse {
              edges {
                ...filterRecipeCourseFields
              }
            }
            
            allWpRecipeCuisine {
              edges {
                ...filterRecipeCuisineFields
              }
            }
            
            allWpRecipeSeason {
              edges {
                ...filterRecipeSeasonFields
              }
            }
            
            allWpRecipeSpecialDiet {
              edges {
                ...filterRecipeSpecialDietFields
              }
            }
            
            # books
            allWpBookLanguage {
              edges {
                ...filterBookLanguageFields
              }
            }
            
            allWpBookPublicationYear {
              edges {
                ...filterBookPublicationYearFields
              }
            }
            
            # hotspot
            allWpHotspotCuisine {
              edges {
                ...filterHotspotCuisineFields
              }
            }
            
            allWpHotspotType {
              edges {
                ...filterHotspotTypeFields
              }
            }
            
            # Video
            allWpVideoMedium {
              edges {
                ...filterVideoMediumFields
              }
            }
            
            allWpVideoType {
              edges {
                ...filterVideoTypeFields
              }
            }
            
          }
        `
    );

    // do not show the filter on pages other
    if (!['recepten', 'hotspots', 'boeken'].includes(slug)) {
        return '';
    }

    // return the information
    return (
        <div className="row" id="filter">
            <div className="col col-no-padding">
                <NavPillsFilter menu={filter_1_menu_data} taxonomies={taxonomies} ul_class="nav nav-pills"/>
            </div>
        </div>
    )
}

/**
 * Export the Filter variable
 */
export default Filter
