/**
 * Pagination component
 */

// Import statements
import React from "react"
import {Link} from "gatsby"

/**
 * Define the Pagination constant
 * @param previousPagePath
 * @param nextPagePath
 * @param basePagePath
 * @param totalPages
 * @param pageNumber
 * @param slug
 * @returns {JSX.Element}
 * @constructor
 */
const Pagination = ({previousPagePath, nextPagePath, basePagePath, totalPages, pageNumber, slug}) => {

    // parse page path
    const getPagePath = page => {

        // for the first page we don't add page number
        if (page === 1 && page <= totalPages) {
            return basePagePath;

            // for other pages we do add a page number
        } else if (page >= 2 && page <= totalPages) {
            return `${basePagePath}page/${page}/`
        }

        return null
    }

    // define the offset
    let offset = 3;

    // calculate the from and to range
    let from_range = ((pageNumber - offset) < 2 ? 1 : (pageNumber - offset));
    let to_range = ((pageNumber + offset) > totalPages ? totalPages : (pageNumber + offset));

    // return the information
    return (
        <nav aria-label="Page navigation example" className="pb-3">
            <ul className="pagination pagination-lg">
                {previousPagePath &&
                <li className="page-item"><Link key={`pagination-previous`} className="page-link" to={previousPagePath}><span
                    className="icon icon-angle-left" aria-hidden="true"></span></Link></li>}
                {Array.from({length: (to_range - from_range) / 1 + 1}, (_, i) => (
                    <li className={`page-item ${i + from_range === pageNumber ? "active" : ""}`}
                        key={`pagination-li-number${i + from_range}`}><Link className="page-link"
                                                                            key={`pagination-link-number${i + from_range}`}
                                                                            to={getPagePath(i + from_range)}>{i + from_range}</Link>
                    </li>
                ))}
                {nextPagePath &&
                <li className="page-item"><Link key={`pagination-next`} className="page-link" to={nextPagePath}><span
                    className="icon icon-angle-right" aria-hidden="true"></span></Link></li>}
            </ul>
        </nav>
    )
}

/**
 * Export the Pagination variable
 */
export default Pagination
