/**
 * Navigation filter
 * We use this on overview pages to narrow down the results
 */

// Import statements
import React from "react"

/**
 * Define the NavPillsFilter constant
 * https://gregbastianelli.com/gatsby-wordpress-menu-api
 * @param menu
 * @param taxonomies
 * @param ul_class
 * @returns {JSX.Element}
 * @constructor
 */
const NavPillsFilter = ({menu, taxonomies, ul_class}) => (
    <ul className={ul_class}>
        {Object.entries(menu).map((taxonomy, index) => {

            // get the children
            const children = taxonomy[1].edges.length
                ? taxonomy[1].edges
                : null

            let tax_description = TaxonomyDescription(taxonomies, taxonomy[0]);

            // do not show
            if (tax_description === '') {
                return '';
            }

            return (<DropdownMenu parent={tax_description} children={children} key={index}/>)
        })}
    </ul>
)

/**
 * Get an menu item
 * @param menuItem
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItem = ({menuItem}) => (
    <li className="nav-item">
        <a href={menuItem.node.uri} className="nav-link">{menuItem.node.name}</a>
    </li>
)

/**
 * Get the taxonomy description
 * @param taxonomies
 * @param taxonomy_graphql_name
 * @returns {string|*}
 * @constructor
 */
function TaxonomyDescription(taxonomies, taxonomy_graphql_name) {

    // get all translations
    let taxonomy_translations = {
        recipe_cuisine: 'Keukens',
        recipe_special_diets: 'Diëten',
        recipe_course: 'Gangen',
        recipe_season: 'Seizoenen',
        recipe_collection: 'Collecties',
        book_language: 'Talen',
        book_publication_year: 'Publicatiejaren',
        hotspot_cuisine: 'Keukens',
        hotspot_types: 'Types',
        video_medium: 'Medium',
        video_type: 'Type'
    };

    // strip allWP from the string
    let search_value = taxonomy_graphql_name.replace('allWp', '')

    // loop through the taxonomies
    for (let i = 0; i < taxonomies.length; i++) {
        const single_name_raw = taxonomies[i].node.graphqlSingleName
        const single_name = `${single_name_raw[0].toUpperCase()}${single_name_raw.slice(1)}`;
        if (single_name === search_value) {
            return taxonomy_translations[taxonomies[i].node.name];
        }
    }

    // return empty string
    return '';
}

/**
 * Parse the dropdown items
 * For example the main li for every taxonomy
 * @param parent
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const DropdownMenu = ({parent, children}) => {

    // return the information
    return (
        <li className={`dropdown`}>
            <button className="dropdown-toggle btn btn-grey-transparent m-2" type="button" data-toggle="dropdown"
                    id={`dropdown${parent}`}>{parent}<span className="caret"/></button>
            <ul className="dropdown-menu" id={parent} aria-labelledby={`dropdown${parent}`}>
                {Object.entries(children).map((child) => {
                    const [key, value] = child;
                    return (
                        <MenuItem menuItem={value} key={key}/>
                    )
                })}
            </ul>
        </li>
    )
}

/**
 * Export the nav pills filter variable
 */
export default NavPillsFilter