/**
 * The hero title for above every page
 * Ensures consistency in lay-out through the website
 */

// Import statements
import React from "react"

/**
 * Define the PageHeader constant
 * @param title
 * @param description
 * @returns {JSX.Element}
 * @constructor
 */
const PageHeader = ({title, description}) => {

    return (
        <div className="row" id="page-header">
            <div className="col">
                <h1>{title}</h1>
                <p dangerouslySetInnerHTML={{__html: description}}/>
            </div>
        </div>
    )
}

/**
* Export the PageHeader variable
*/
export default PageHeader
